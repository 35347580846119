
import { useState, useEffect, SetStateAction } from "react";
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import { ReservedKeyword } from "../../interfaces/reservedKeyword";
import DropdownFranchise from "../../components/dropdowns/DropdownFranchise";
import DropdownLocationMultiple from "../../components/dropdowns/DropdownLocationMultiple";
import { useData } from "../../hooks/useData";
import VideoPlayerKinesis from '../../components/cameras/VideoPlayerKinesis';
import LiveButton from '../../components/cameras/LiveButton';
import { getMothershipMetrics, getMothershipLogs } from '../../utils/api';
import { useCamera } from '../../hooks/useCamera';
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material'; // Import MUI ToggleButtonGroup


const Mothership = () => {
  const [allLocationMetrics, setAllLocationMetrics] = useState<Map<string, any> | null>(null); // Track the metrics in state
  const [loading, setLoading] = useState(true); // Track loading state  
  const [sortByTimestamp, setSortByTimestamp] = useState(false); // Toggle for sorting order
  const [showMetrics, setShowMetrics] = useState({
    internetConnection: true,
    ramUsage: true,
    diskUsage: true,
    uploadSpeed: true,
    restartCv: true,
    restartAll: true,
    collectionTimestamp: true,
  }); // State to manage visibility of metrics


  const {
    isGrid,
    setKinesisVideo,
  } = useCamera();



  const { selectedFranchise, selectedLocations } = useData();

  // Get AWS credentials on load.
  useEffect(() => {
    async function setUpKinesis(): Promise<void> {
      const userCredentials = await Auth.currentCredentials();
      AWS.config.update({
        region: import.meta.env.VITE_AWS_REGION,
        credentials: Auth.essentialCredentials(userCredentials),
      });
      setKinesisVideo(new AWS.KinesisVideo());
    }
    setUpKinesis().catch((error) => {
      console.error('Error setting up Kinesis', error);
    });
  }, []);

  // Fetch metrics when selectedFranchise or selectedLocations changes
  useEffect(() => {
    if (selectedFranchise !== null && selectedLocations.length > 0) {
      const locationIDs = selectedLocations.map(obj => obj.id);
      console.log('Fetching metrics for locations:', locationIDs);

      // Use async function inside the useEffect to handle the promise properly
      const fetchMetrics = async () => {
        try {
          const locationMetrics = await getMothershipMetrics(selectedFranchise.id, locationIDs); // Fetch metrics for the selected franchise and locations
          console.log('locationMetrics',locationMetrics)
          setAllLocationMetrics(new Map(Object.entries(locationMetrics))); // Set the metrics state
          setLoading(false); // Set loading to false after fetching data
        } catch (error) {
          console.error('Error fetching location metrics:', error);
          setLoading(false); // Stop loading in case of error
        }
      };

      fetchMetrics(); // Call the async function
    }
  }, [selectedFranchise, selectedLocations]); // Ensure selectedLocations and selectedFranchise are dependencies







    // Toggle the order of video players (oldest to newest collection timestamp)
    const handleToggleSortOrder = () => {
      setSortByTimestamp(prevState => !prevState);
    };
  
    // Toggle visibility of specific metrics
    const handleToggleMetric = (metric: string) => {
      setShowMetrics(prevState => {
        const newState = { ...prevState, [metric]: !prevState[metric] };
        console.log(newState); // Add this to check the new state
        return newState;
      });
    };
    
  
    const sortedLocations = sortByTimestamp
      ? [...selectedLocations].sort((a, b) => {
          const aTimestamp = allLocationMetrics?.get(a.id)?.timestamp || 0;
          const bTimestamp = allLocationMetrics?.get(b.id)?.timestamp || 0;
          return aTimestamp - bTimestamp;
        })
      : selectedLocations;




  return (
    <div className="cont">
      <div className="row">
        <div className="col-10 col-m-10 p-2 mx-auto"></div>
        <div className="flex flex-wrap justify-start gap-2 col-10 col-m-10 p-2 mx-auto">
          {/* Franchise Selector */}
          <DropdownFranchise />
          {/* Location Selector */}
          <DropdownLocationMultiple />
          <LiveButton />

          {/* Toggle Button for Sorting */}
          <Button variant="contained" color="primary" onClick={handleToggleSortOrder}>
            {sortByTimestamp ? 'Sort by Newest' : 'Sort by Oldest'}
          </Button>

          {/* Toggle Buttons for Metrics */}
          <ToggleButtonGroup value={null} exclusive>
            {Object.keys(showMetrics).map((metric) => (
              <ToggleButton
                key={metric}
                value={metric}
                selected={showMetrics[metric]}
                onChange={() => handleToggleMetric(metric)}
              >
                {metric.replace(/([A-Z])/g, ' $1').toUpperCase()} {/* Capitalize metric name */}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      </div>

      {/* Video Players Grid */}
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4">
        {loading ? (
          <div>Loading metrics...</div>
        ) : (
          sortedLocations.map((location) => {
            const metrics = allLocationMetrics?.get(location.id) || null;
              return (
                <VideoPlayerKinesis
                  key={location.id}
                  location={location}
                  camera="MONITOR"
                  metrics={metrics || {}}  // Passing null or an empty object if no metrics are available
                  franchise={selectedFranchise}
                  showMetrics={showMetrics}
                />
              );
            
          })
        )}
      </div>
    </div>
  );
};

export default Mothership;
