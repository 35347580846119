/**
 * Presentational component for creating a group of locations.
 */

import type { Location } from '../../../../types/Location';
import SectionTitle from './SectionTitle';
import DropdownSelectMultiple from '../../../dropdowns/DropdownSelectMultiple';
import StandardButton from '../../../buttons/StandardButton';
import { Input } from "@/components/ui/input"
import { Franchise } from '@/types/Franchise';
import { useState } from 'react';

export default function CreateGroupSection({
    locations,
    franchise,
    name,
    setName,
    group,
    isAdmin,
    handlePrivateChange,
    handleChange,
    handleCreate,
  }: {
    locations: Location[];
    franchise: Franchise;
    name: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
    group: string[];
    isAdmin: boolean;
    handlePrivateChange: (isPrivate: boolean) => void;
    handleChange: (id: string) => void;
    handleCreate: (name: string, group: string[]) => void;
  }): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);

    const options = locations.map((location) => ({
      id: location.id,
      value: location.displayName,
    }));

    const selected = locations.filter((location) => group.includes(location.id))
    .map((location) => ({
      id: location.id,
      value: location.displayName,
    }));

    const inputClass = 'cursor-pointer w-4 h-4 text-blue-600 bg-slate-100 border-slate-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-slate-800 focus:ring-2 dark:bg-slate-700 dark:border-slate-600';
    const labelClass = 'ms-2 text-sm font-medium text-slate-900 dark:text-slate-300';

    return (
      <section>
        <SectionTitle title="Create New Location Groups" />
        <p className="text-slate-600 dark:text-slate-400 mb-4 text-sm">
          Add locations to a new {franchise.displayName} group from this list.
        </p>
        <div className="grid grid-cols-3 gap-4">
          <Input
            placeholder="New group name"
            value={name ?? ""}
            onChange={(event) => setName(event.target.value)}
            className="text-slate-800 dark:text-slate-100 bg-white dark:bg-slate-800 shadow-lg rounded-lg border border-slate-100 dark:border-slate-700"
          />
          <DropdownSelectMultiple
            options={options}
            selected={selected}
            handleChange={handleChange}
            title={`${group.length} Selected`}
            dataCy="create-new-group-dropdown"
            open={isOpen}
            setOpen={setIsOpen}
          />
          <StandardButton
            dataCy="create-new-group-button"
            onClick={() => handleCreate(name, group)}
          >
            Create
          </StandardButton>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div className='col-start-3'>
            <div className='flex'>
              <div className="flex items-center me-4">
                <input id="private-public" type="radio" value="" name="private-public-group" className={`${inputClass}`} 
                  onChange={() => handlePrivateChange(true)}/>
                <label htmlFor="private-public" className={`${labelClass}`}>Private</label>
              </div>
              <div className="flex items-center me-4">
                {isAdmin 
                  ? (<input id="private-public" type="radio" value="" name="private-public-group" className={`${inputClass}`} 
                    onChange={() => handlePrivateChange(false)}/>) 
                  : (<input disabled id="private-public" type="radio" value="" name="private-public-group" className={`${inputClass}`} 
                    onChange={() => handlePrivateChange(false)}/>)}
                <label htmlFor="private-public" className={`${labelClass}`}>Public</label>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }