import { useState, useEffect } from 'react';
import { patchUserProfile } from '../../../utils/api';
import type { UserProfile } from '../../../types/UserProfile';
import { useData } from '../../../hooks/useData';
import { useToast } from '../../../hooks/useToast';
import { useAuth } from '../../../hooks/useAuth';
import DefaultLocationSection from './sections/DefaultLocationSection';
import LocationNotificationsSection from './sections/LocationNotificationsSection';
import StandardButton from '../../buttons/StandardButton';

export default function AccountPanel(): JSX.Element {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [defaultLocation, setDefaultLocation] = useState<UserProfile['locations'][0] | undefined>();

  const { setToast } = useToast();
  const { profile, selectedFranchise } = useData();
  const { signOut } = useAuth();

  // 1) Sync React state with "profile" from props/context.
  useEffect(() => {
    if (!profile) return;

    // Update local userProfile state
    setUserProfile(profile);

    // Determine default location from localStorage
    const storedDefaultLocation = localStorage.getItem('defaultLocation');
    const matchingLocation = profile.locations.find(
      (location) => location.id === storedDefaultLocation
    );

    if (matchingLocation) {
      setDefaultLocation(matchingLocation);
    } else {
      // If no default found in localStorage, you can pick the first one or leave undefined
      setDefaultLocation(profile.locations[0]);
    }
  }, [profile]);

  // Handle selecting a new default location.
  function handleSelectDefaultLocation(id: string): void {
    if (!userProfile) return;

    const newDefaultLocation = userProfile.locations.find(
      (location) => location.id === id
    );
    if (newDefaultLocation) {
      localStorage.setItem('defaultLocation', newDefaultLocation.id);
      setDefaultLocation(newDefaultLocation);
    }
  }

  // Handle selecting a location to receive notifications for.
  function handleSelectLocationForNotifications(id: string): void {
    if (!userProfile) return;

    const alreadyIncluded = userProfile.notificationLocations.includes(id);
    const notificationLocations = alreadyIncluded
      ? userProfile.notificationLocations.filter((locId) => locId !== id)
      : [...userProfile.notificationLocations, id];

    // Update local state
    setUserProfile({ ...userProfile, notificationLocations });

    // Communicate with Flutter app
    console.log(
      JSON.stringify({
        [alreadyIncluded ? 'remove_notification' : 'add_notification']: id,
      })
    );
  }

  // Handle submitting new notification locations.
  async function handleSaveProfile(): Promise<void> {
    if (!userProfile) return;

    await patchUserProfile(userProfile.notificationLocations);
    setToast({
      show: true,
      message: 'Profile updated',
      type: 'success',
    });
  }

  return (
    <div className="grow">
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">
          My Account
        </h2>

        {/* Set Default Location */}
        {userProfile && defaultLocation && (
          <DefaultLocationSection
            userProfile={userProfile}
            selectedLocation={defaultLocation}
            handleChange={handleSelectDefaultLocation}
          />
        )}

        {/* Location Notifications */}
        {userProfile && (
          <LocationNotificationsSection
            locations={userProfile.locations.filter((location => location.franchiseId === selectedFranchise?.id))}
            selectedLocations={userProfile.locations.filter((location => location.franchiseId === selectedFranchise?.id)).filter((location) =>
              userProfile.notificationLocations.includes(location.id)
            )}
            handleSelect={handleSelectLocationForNotifications}
            handleSave={() => {
              handleSaveProfile().catch(console.error);
            }}
          />
        )}

        {/* Sign Out */}
        <StandardButton
          onClick={() => {
            signOut().catch(console.error);
          }}
          dataCy="sign-out-button"
        >
          Sign Out
        </StandardButton>
      </div>
    </div>
  );
}
