/**
 * Frontend interface for interacting with the API.
 */
import { getUserToken } from './user';
import type { Location } from '../types/Location';
import type { Order, OrderItem, OrderResult } from '../types/Order';
import type { Detection } from '../types/Detection';
import type { Average } from '../types/Average';
import type { Rank, RankPayload } from '../types/Rank';
import type { DayPartData } from '../types/DayPartData';
import type { RegionsStats } from '../types/RegionsStats';
import type { Camera } from '../types/Camera';
import type { UserProfile } from '../types/UserProfile';
import type { DayPart } from '../types/DayPart';
import { dateToUtc } from './time';
import { type Roles } from '../types/Roles';
import { type FranchiseUser } from '../types/FranchiseUser';
import { type FranchiseLocation } from '../types/FranchiseLocation';
import { type FranchiseConfiguration } from '../types/FranchiseConfiguration';
import { type Stack } from '@/types/Stack';
import { type GroupResponse } from '@/types/GroupResponse';

export async function getAllLabelmeImages(franchiseId: number, locationId: number): Promise<object> {
  const urls = await getLabelmeImages(franchiseId, locationId);
  const results = await processImageUrls(urls);

  return results;
}

export async function getMothershipLogs(franchiseId: string, locations: string[]): Promise<any> {
  const token = await getUserToken();
  if (!token) {
    console.error('No token found.');
    return null;
  }

  try {
    console.log('we tryin');
    // Create the query params.
    const queryParams = new URLSearchParams();

    // Append the location IDs to the query params.
    locations.forEach(locationId => {
      queryParams.append('locationId', locationId);
    });

    // Add default value for amountLines
    queryParams.append('amountLines', '2000'); // Default to 2000 or any value expected by the API

    // Make the request to the API with query parameters.
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/locations/logs?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    console.log('Response:', response); // Print the entire response object

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Failed to fetch mothership logs:', errorText);
      return null;
    }

    // Since the body is expected to be JSON, parse it
    const data = await response.json();
    console.log('Response Body:', data); // Print the parsed body

    // const locationLogs = JSON.parse(data.body).locationLogs;
    // console.log(locationLogs)
    console.log('this is happening');
    return data.latestLogs; // Return the locationLogs map
  } catch (error) {
    console.error('Error fetching mothership logss:', error);
    return null;
  }
}

export async function getMothershipMetrics(franchiseId: string, locations: string[]): Promise<any> {
  const token = await getUserToken();
  if (!token) {
    console.error('No token found.');
    return null;
  }

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();

    // Append the location IDs to the query params.
    locations.forEach(locationId => {
      queryParams.append('locationId', locationId);
    });

    // Make the request to the API with query parameters.
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/locations/metrics?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    console.log('Response:', response); // Print the entire response object

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Failed to fetch mothership metrics:', errorText);
      return null;
    }

    // Since the body is expected to be JSON, parse it
    const data = await response.json();
    console.log('Response Body:', data); // Print the parsed body

    // const locationMetrics = JSON.parse(data.body).locationMetrics;
    // console.log(locationMetrics)
    console.log('this is happening');
    return data.locationMetrics; // Return the locationMetrics map
  } catch (error) {
    console.error('Error fetching mothership metrics:', error);
    return null;
  }
}

// export async function getMothershipMetrics(franchiseId: string, locations: string[]): Promise<any> {
//   const token = await getUserToken();
//   if (!token) {
//     console.error('No token found.');
//     return null;
//   }

//   try {
//     // Create the query params.
//     const queryParams = new URLSearchParams();

//     // Append the location IDs to the query params.
//     locations.forEach(locationId => {
//       queryParams.append('locationIds', locationId);
//     });

//     // Make the request to the API with query parameters.
//     const response = await fetch(
//       `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/locations/metrics?${queryParams.toString()}`,
//       {
//         method: 'GET',
//         headers: {
//           Authorization: token,
//         },
//       }
//     );
//     console.log('response', response)

//     if (!response.ok) {
//       const errorText = await response.text();
//       console.error('Failed to fetch mothership metrics:', errorText);
//       return null;
//     }

//     const data = await response.json();
//     const locationMetrics = JSON.parse(data.body).locationMetrics;
//     return locationMetrics; // Return the locationMetrics map

//   } catch (error) {
//     console.error('Error fetching mothership metrics:', error);
//     return null;
//   }
// }

// export async function getMothershipMetrics(locationIDs: string[],franchiseId: string): Promise<any> {
//   console.log('a')
//   const token = await getUserToken();
//   if (!token) {
//     console.error('No token found.');
//     return null;
//   }
//   console.log('b')

//   try {
//     const response = await fetch(
//       `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/locations/metrics`,
//       {
//         method: 'GET',
//         headers: {
//           Authorization: token,
//         },
//       }
//     );
//     console.log('c')

//     if (!response.ok) {
//       const errorText = await response.text();
//       console.error('Failed to fetch mothership metrics:', errorText);
//       return null;
//     }

//     const data = await response.json();
//     const locationMetrics = JSON.parse(data.body).locationMetrics;
//     return locationMetrics; // Return the locationMetrics map

//   } catch (error) {
//     console.error('Error fetching mothership metrics:', error);
//     return null;
//   }
// }

// export async function getMothershipLogs(franchiseId: string): Promise<any> {
//   const token = await getUserToken();
//   if (!token) {
//     console.error('No token found.');
//     return null;
//   }

//   try {
//     const response = await fetch(
//       `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/locations/logs`,
//       {
//         method: 'GET',
//         headers: {
//           Authorization: token,
//         },
//       }
//     );

//     if (!response.ok) {
//       const errorText = await response.text();
//       console.error('Failed to fetch mothership logs:', errorText);
//       return null;
//     }

//     const data = await response.json();
//     const locationLogs = JSON.parse(data.body).locationLogs;
//     return locationLogs; // Return the locationLogs map

//   } catch (error) {
//     console.error('Error fetching mothership logs:', error);
//     return null;
//   }
// }

export async function callMothership(machine_numbers: string, action: string): Promise<boolean> {
  const token = await getUserToken();

  if (!token) {
    console.error('No token found.');
    return false;
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/mothership/run_playbook`,
      {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          machine_numbers,
          action,

        }),
      }
    );

    if (!response.ok) {
      const errorText = await response.text(); // Read the error message
      console.error('Failed to post mothership command:', errorText);
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error calling mothership:', error);
    return false;
  }
}

export async function callMothershipDotenv(machine_numbers: string, variable: string, dotenv_action: string, value: string): Promise<boolean> {
  const token = await getUserToken();
  console.log('cmd', machine_numbers, variable, dotenv_action, value);

  if (!token) {
    console.error('No token found.');
    return false;
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/mothership/run_configure`,
      {
        method: 'POST',
        headers: {
          Authorization: token, // Assuming you still need this header
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          machine_numbers, // Pass machine numbers
          variable, // Pass the variable
          dotenv_action, // Pass dotenv action (e.g., ADD, REMOVE)
          value, // Pass the value (e.g., PASSTHROUGH)
        }),
      }
    );
    console.log(response);

    if (!response.ok) {
      const errorText = await response.text(); // Read the error message
      console.error('Failed to post mothership command:', errorText);
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error calling mothership:', error);
    return false;
  }
}

async function processImageUrls(imageMap: Map<string, string>): Promise<object> {
  const results = {};

  for (const [key, url] of imageMap.entries()) {
    try {
      const imageData = await fetchImageData(url);
      results[key] = imageData;
    } catch (error) {
      console.error(`Error processing URL ${url}:`, error);
    }
  }

  return results;
}

async function fetchImageData(url: string): Promise<string> {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch image data: ${response.status} ${response.statusText}`);
    }

    const blob = await response.blob();
    return await convertBlobToBase64(blob);
  } catch (error) {
    console.error(`Error fetching image data from ${url}:`, error);
    throw error;
  }
}

async function convertBlobToBase64(blob: Blob): Promise<string> {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => { resolve(reader.result as string); };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export async function getLabelmeImages(franchiseId: number, locationId: number): Promise<Map<string, string>> {
  const token = await getUserToken();

  if (!token) {
    console.error('No token found.');
    return new Map();
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/locations/${locationId}/regions`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );
    console.log('Response details:', response);

    if (!response.ok) {
      console.error('Failed to fetch images:', response.status, response.statusText);
      return new Map();
    }

    const data = await response.json();
    console.log('Parsed JSON data:', data);

    return new Map(Object.entries(data));
  } catch (error) {
    console.error('Error fetching images:', error);
    return new Map();
  }
}

// async function fetchImageData(url: string): Promise<any> {
//   console.log("fetching....................")
//   try {
//     const response = await fetch(url);
//     console.log('here is resp fid', response)
//     if (!response.ok) {
//       throw new Error(`Failed to fetch image data: ${response.status} ${response.statusText}`);
//     }
//     // Assuming the response is JSON. If it's another type, adjust accordingly.
//     return await response.json();
//   } catch (error) {
//     console.error(`Error fetching image data from ${url}:`, error);
//     throw error;
//   }
// }

export async function postLabelmeImages(franchiseId: number, locationId: number, jsImage: string, fileName: string): Promise<boolean> {
  const token = await getUserToken();
  console.log(franchiseId, locationId, jsImage, fileName);

  if (!token) {
    console.error('No token found.');
    return false;
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/locations/${locationId}/regions`,
      {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fileType: 'application/json',
          fileKey: fileName,
          fileContent: jsImage,

        }),
      }
    );
    console.log(response);

    if (!response.ok) {
      console.log('b');
      const errorText = await response.text(); // Read the error message
      console.error('Failed to post images:', errorText);
      return false;
    }
    console.log('c');

    return true;
  } catch (error) {
    console.log('d');
    console.error('Error fetching images:', error);
    return false;
  }
}

export async function deleteLocation(franchiseId: string, locationId: string): Promise<void> {
  const token = await getUserToken();

  if (!token) {
    console.error('No token found.');
    return;
  }

  await fetch(`${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/locations`,
    {
      method: 'DELETE',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        locationId
      }),
    }
  );
}

/**
 *
 * @returns The user profile.
 */
export async function getUserProfile(): Promise<UserProfile> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return {
      locations: [],
      franchiseRoles: new Map<string, Roles>(),
      notificationLocations: [],
      franchises: []
    };
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/v2/user/profile`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return {
        locations: [],
        franchiseRoles: new Map<string, Roles>(),
        notificationLocations: [],
        franchises: []
      };
    }

    const data = await response.json();
    // Convert franchiseRoles from a JS object to a Map
    data.franchiseRoles = new Map<string, Roles>(
      Object.entries(data.franchiseRoles ?? {})
    );

    // Convert each location's customPermissions from a JS object to a Map
    data.locations = data.locations.map((loc: Location) => ({
      ...loc,
      customPermissions: new Map<string, unknown>(
        Object.entries(loc.customPermissions ?? {})
      ),
    }));
    return data as UserProfile;
  } catch (error) {
    console.error(error);
  }

  return {
    locations: [],
    franchiseRoles: new Map<string, Roles>(),
    notificationLocations: [],
    franchises: []
  };
}

/**
 * @description Get the orders for locations on a given day.
 */
export async function getLocationOrders(
  franchiseId: string,
  date: Date,
  locations: Location[]
): Promise<Order[]> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return [] as Order[];
  }

  if (locations.length === 0) {
    return [] as Order[];
  }

  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);

  const start = dateToUtc(date, locations[0].timezone);
  const end = dateToUtc(date, locations[0].timezone);
  end.setDate(end.getDate() + 1);

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();
    queryParams.append('startDate', start.toISOString());
    queryParams.append('endDate', end.toISOString());

    for (let i = 0; i < locations.length; i++) {
      queryParams.append('locationId', locations[i].id);
    }

    // Make the request.
    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/franchises/${franchiseId}/orders?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
     // Group orderItems by orderId in each Order
     const ordersWithItems = data.orders.map((order: Order) => ({
      ...order,
      items: data.orderItems.filter((item: OrderItem) => item.orderId === order.orderId),
    }));
    return ordersWithItems;
  } catch (error) {
    console.error(error);
  }

  return [];
}

/**
 * @description Get the order totals for a week at a location.
 */
export async function getOrderTotals(
  franchiseId: string,
  startDate: Date,
  endDate: Date,
  locations: string[],
  timezone: string
): Promise<
  Array<{
    date: string;
    total: number;
  }>
> {
  const token = await getUserToken();

  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);

  endDate.setHours(0);
  endDate.setMinutes(0);
  endDate.setSeconds(0);

  const start = dateToUtc(startDate, timezone);
  const end = dateToUtc(endDate, timezone);

  if (token === null) {
    console.log('No token found.');
    return [];
  }

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();
    queryParams.append('startDate', start.toISOString());
    queryParams.append('endDate', end.toISOString());

    for (let i = 0; i < locations.length; i++) {
      queryParams.append('locationId', locations[i]);
    }

    // Make the request.
    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/franchises/${franchiseId}/orders/totals?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }

  return [];
}

/**
 * @description Get the list of users for a given franchise
 */
export async function getFranchiseUsers(
  franchiseId: string
): Promise<
  { userData: FranchiseUser[], franchiseLocations: FranchiseLocation[], franchiseConfiguration: FranchiseConfiguration | null }
> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return { userData: [], franchiseLocations: [], franchiseConfiguration: null };
  }

  try {
    // Make the request.
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/users`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return { userData: [], franchiseLocations: [], franchiseConfiguration: null };
    }

    const data = await response.json();

    // Convert each FranchiseUser's locationPermissions to a Map
    const userData: FranchiseUser[] = data.userData.map((user: FranchiseUser) => {
      // Safely handle if locationPermissions is missing or null
      if (user.locationPermissions && typeof user.locationPermissions === 'object') {
        return {
          ...user,
          locationPermissions: new Map(
            Object.entries(user.locationPermissions).map(([locationId, permissions]) => [
              locationId,
              new Map(Object.entries(permissions as Record<string, unknown>)),
            ])
          ),
        };
      }

      // If there's no locationPermissions, return a user with an empty Map
      return {
        ...user,
        locationPermissions: new Map(),
      };
    });

    return {
      userData,
      franchiseLocations: data.franchiseLocations,
      franchiseConfiguration: data.franchiseConfiguration
    };
  } catch (error) {
    console.error(error);
  }

  return { userData: [], franchiseLocations: [], franchiseConfiguration: null };
}

/**
 * @description Get the list of users for a given franchise
 */
export async function updateFranchiseConfiguration(
  franchiseId: string,
  franchiseConfiguration: FranchiseConfiguration
): Promise<boolean> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return false;
  }

  try {
    // Make the request.
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/users`,
      {
        method: 'POST',
        headers: {
          Authorization: token,
        },
        body: JSON.stringify(franchiseConfiguration)
      }
    );

    if (!response.ok) {
      return false;
    }
    return true;
  } catch (error) {
    console.error(error);
  }

  return false;
}

/**
 * @description Get the detections for a location.
 */
export async function getDetections(
  startDate: Date,
  endDate: Date,
  locations: string[],
  isDriveThru: boolean,
  franchiseId?: string
): Promise<Detection[]> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return [] as Detection[];
  }

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();
    queryParams.append(
      'startDate',
      `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`
    );
    queryParams.append(
      'endDate',
      `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
    );
    queryParams.append('isDriveThru', isDriveThru.toString());

    for (let i = 0; i < locations.length; i++) {
      queryParams.append('locationId', locations[i]);
    }

    if (franchiseId) {
      queryParams.append(
        'franchiseId',
        franchiseId.toString()
      );
    }

    // Make the request.
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/detections?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }

  return [];
}

/**
 * @description Get the averages for a location.
 */
export async function getAverages(
  franchiseId: string,
  startDate: Date,
  endDate: Date,
  locations: string[],
  isDriveThru: boolean,
  options: { signal?: AbortSignal } = {}
): Promise<Average[]> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return [] as Average[];
  }

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();
    queryParams.append('startDate', startDate.toISOString());
    queryParams.append('endDate', endDate.toISOString());
    queryParams.append('isDriveThru', isDriveThru.toString());

    for (let i = 0; i < locations.length; i++) {
      queryParams.append('locationId', locations[i]);
    }

    // Make the request.
    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/franchises/${franchiseId}/averages/daily?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
        signal: options.signal, // Pass the abort signal here
      }
    );

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }

  return [];
}

/**
 * @description Get the ranks for a location.
 */
export async function getRanks(
  franchiseId: string,
  startDate: Date,
  endDate: Date,
  locations: string[],
  isDriveThru: boolean
): Promise<RankPayload> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return {} as RankPayload;
  }

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();
    queryParams.append(
      'startDate',
      `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`
    );
    queryParams.append(
      'endDate',
      `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
    );
    queryParams.append('isDriveThru', isDriveThru.toString());

    for (let i = 0; i < locations.length; i++) {
      queryParams.append('locationId', locations[i]);
    }

    // Make the request.
    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/franchises/${franchiseId}/ranks?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return {} as RankPayload;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }

  return {} as RankPayload;
}

/**
 * @description Get the regions stats for a location.
 */
export async function getRegionsStats(
  franchiseId: string,
  startDate: Date,
  endDate: Date,
  locations: string[],
  isDriveThru: boolean,
  options: { signal?: AbortSignal } = {}
): Promise<RegionsStats[]> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return [] as RegionsStats[];
  }

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();

    startDate.setHours(0);
    endDate.setHours(0);

    queryParams.append(
      'startDate',
      startDate.toISOString().slice(0, 10)
    );
    queryParams.append(
      'endDate',
      endDate.toISOString().slice(0, 10)
    );
    queryParams.append('isDriveThru', isDriveThru.toString());

    for (let i = 0; i < locations.length; i++) {
      queryParams.append('locationId', locations[i]);
    }

    // Make the request.
    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/v2/franchises/${franchiseId}/regions?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
        signal: options.signal, // Pass the abort signal here
      }
    );

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }

  return [];
}

/**
 * @description Get the regions stats for a location.
 */
export async function getStackDataByLocationId(
  franchiseId: string,
  startDate: Date,
  endDate: Date,
  locations: string[],
  options: { signal?: AbortSignal } = {}
): Promise<Stack | undefined> {
  const token = await getUserToken();
  const results: Stack | undefined = undefined;

  if (token === null) {
    console.log('No token found.');
    return results; // Return undefined if no token
  }

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();

    queryParams.append('start_date', startDate.toISOString().slice(0, 10));
    queryParams.append('end_date', endDate.toISOString().slice(0, 10));
    queryParams.append('timezone', 'America/Los_Angeles');
    queryParams.set('location_ids', locations.join(','));

    // Make the request.
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/stacks-driveoffs?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
        signal: options.signal, // Pass the abort signal here
      }
    );

    if (!response.ok) {
      console.error(`Failed to fetch: ${response.status} ${response.statusText}`);
      return results; // Return undefined if response is not ok
    }

    const data = await response.json();
    return data as Stack;
  } catch (error) {
    if (error instanceof Error) { // Type guard to check if it's an Error
      if (error.name === 'AbortError') {
        console.log('Fetch request was aborted');
      } else {
        console.error('Error fetching stack data:', error.message);
      }
    } else {
      console.error('Unexpected error:', error); // Handle unknown types
    }
    return results; // Return undefined on error
  }
}

/**
 * @description Get day part data for a location.
 */
export async function getDayPartsData(
  franchiseId: string,
  startDate: Date,
  endDate: Date,
  locations: string[],
  isDriveThru: boolean,
  options: { signal?: AbortSignal } = {}
): Promise<DayPartData[]> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return [] as DayPartData[];
  }

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();

    queryParams.append(
      'startDate',
      `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`
    );
    queryParams.append(
      'endDate',
      `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
    );
    queryParams.append('isDriveThru', isDriveThru.toString());

    for (let i = 0; i < locations.length; i++) {
      queryParams.append('locationId', locations[i]);
    }

    // Make the request.
    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/franchises/${franchiseId}/day-parts?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
        signal: options.signal, // Pass the abort signal here
      }
    );

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }

  return [];
}

/**
 * @description Get the cameras for a location.
 * @param location The location to get cameras for.
 * @returns The cameras for a location.
 */
export async function getLocationCameras(
  location: Location
): Promise<Camera[]> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return [] as Camera[];
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/franchises/${
        location.franchiseId
      }/locations/${location.id}/cameras`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return [] as Camera[];
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }

  return [] as Camera[];
}

/**
 * @param franchiseId The franchise id of the location
 * @param locationId The location id to update.
 * @param displayName The display name to update.
 * @param dayParts The day parts to update.
 * @returns True if the location settings were updated successfully.
 */
export async function putLocationSettings(
  franchiseId: string,
  locationId: string,
  displayName: string,
  dayParts: DayPart[]
): Promise<boolean> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return false;
  }

  try {
    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/franchises/${franchiseId}/locations/${locationId}/settings`,
      {
        method: 'PUT',
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({
          displayName,
          dayParts,
        }),
      }
    );

    if (!response.ok) {
      return false;
    }

    return true;
  } catch (error) {
    console.error(error);
  }

  return false;
}

/**
 *
 * @param notificationLocations - The notification locations to update.
 * @returns The updated user profile.
 */
export async function patchUserProfile(
  notificationLocations: string[]
): Promise<UserProfile> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return {
      locations: [],
      franchiseRoles: new Map<string, Roles>(),
      notificationLocations: [],
      franchises: []
    };
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/user/profile`,
      {
        method: 'PATCH',
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({
          notificationLocations,
        }),
      }
    );

    if (!response.ok) {
      return {
        locations: [],
        franchiseRoles: new Map<string, Roles>(),
        notificationLocations: [],
        franchises: []
      };
    }

    const data = await response.json();
    return data as UserProfile;
  } catch (error) {
    console.error(error);
  }

  return {
    locations: [],
    franchiseRoles: new Map<string, Roles>(),
    notificationLocations: [],
    franchises: []
  };
}

/**
 *
 * @param notificationLocations - The notification locations to update.
 * @returns The updated user profile.
 */
export async function postUserProfile(
  newUser: FranchiseUser
): Promise<boolean> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return false;
  }

  try {
    const franchiseId = newUser.franchiseId;
    const role = newUser.userRole;
    const email = newUser.email;
    const locations = newUser.locationIds.map((locId) => { return { locationId: locId, franchiseId }; });

    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/user/profile`,
      {
        method: 'POST',
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({
          locations,
          franchiseId,
          role,
          email
        }),
      }
    );

    if (!response.ok) {
      return false;
    }

    return true;
  } catch (error) {
    console.error(error);
  }

  return false;
}

/**
 *
 * @param notificationLocations - The notification locations to update.
 * @returns The updated user profile.
 */
export async function updateUserProfile(
  user: FranchiseUser
): Promise<boolean> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return false;
  }

  try {
    const userId = user.userId;
    const franchiseId = user.franchiseId;
    const role = user.userRole;
    const email = user.email;
    const locations = Array.from(user.locationPermissions.entries()).map(
      ([locationId, customPermissionsMap]) => {
        // Convert the customPermissions map into a plain object
        const customPermissions = Object.fromEntries(
          customPermissionsMap.entries()
        );

        return { locationId, customPermissions, franchiseId };
      }
    );

    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/v2/user/profile`,
      {
        method: 'PUT',
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({
          locations,
          userId,
          franchiseId,
          role,
          email
        }),
      }
    );

    if (!response.ok) {
      return false;
    }

    return true;
  } catch (error) {
    console.error(error);
  }

  return false;
}

/**
 *
 * @param notificationLocations - The notification locations to update.
 * @returns The updated user profile.
 */
export async function deleteUserProfile(
  user: FranchiseUser
): Promise<boolean> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return false;
  }

  try {
    const franchiseId = user.franchiseId;
    const userId = user.userId;

    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/user/profile`,
      {
        method: 'DELETE',
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({
          userId,
          franchiseId
        }),
      }
    );

    if (!response.ok) {
      return false;
    }

    return true;
  } catch (error) {
    console.error(error);
  }

  return false;
}

/**
 * Create a location group.
 * @param groupName - Name of the new location group.
 * @param franchiseId - Franchise of the group.
 * @param locationIds - The location IDs of the group.
 * @param isPrivate - boolean private/public group
 * @returns Name of the newly created group.
 */
export async function createGroup(
  groupName: string,
  franchiseId: string,
  locationIds: string[],
  isPrivate: boolean,
): Promise<string> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return '';
  }

  let queryString;
  if (isPrivate) {
    queryString = `user/groupFranchise/${franchiseId}/group`;
  } else {
    queryString = `franchises/${franchiseId}/group`;
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/${queryString}`,
      {
        method: 'POST',
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({
          groupName,
          locationIds,
        }),
      }
    );

    if (!response.ok) {
      return '';
    }

    if (response.ok) {
      const data = await response.json();
      return data as string;
    }
  } catch (error) {
    console.error(error);
  }

  return '';
}

/**
 * Create a location group.
 * @param groupName - Name of the new location group.
 * @param franchiseId - Franchise of the group.
 * @returns Name of the newly created group.
 */
export async function deleteGroup(
  groupName: string,
  franchiseId: string,
): Promise<string> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return '';
  }

  try {
    const response = await fetch(
      // check to make sure that is the right endpoint ***
      `${import.meta.env.VITE_API_URL}/user/groupFranchise/${franchiseId}/group/${groupName}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return '';
    }

    if (response.ok) {
      const data = await response.json();
      return data as string;
    }
  } catch (error) {
    console.error(error);
  }

  return '';
}

/**
 * Query a location group by name.
 * @param group - The LocationGroup, including name and userOwned.
 * @param franchiseId - The franchise ID of the group.
 * @returns List of all location IDs in the group.
 */
export async function getGroupLocationsByName(
  group: GroupResponse,
  franchiseId: string,
): Promise<string[]> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return [];
  }

  let queryString;
  if (group.private) {
    queryString = `user/groupFranchise/${franchiseId}/group/${group.groupId}`;
  } else {
    queryString = `franchises/${franchiseId}/group/${group.groupId}`;
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/${queryString}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        }
      }
    );

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
    return data as string[];
  } catch (error) {
    console.error(error);
  }

  return [];
}

/**
 * Query all location groups for a single user.
 * @param franchiseId - The franchise ID that the groups belong to.
 * @returns List of all user's location group names / IDs.
 */
export async function getAllGroups(
  franchiseId: string
): Promise<GroupResponse[]> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return [];
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/groups`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        }
      }
    );

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
    return data as GroupResponse[];
  } catch (error) {
    console.error(error);
  }

  return [];
}
