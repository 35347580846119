import { useCallback, useEffect, useState } from "react";
import { mapDataAreaToShape, mapLabelmeToDatashare } from "../../utils/loader";
import { Size } from "../../interfaces/size";
import { ImageData } from "../../interfaces/imageData";
import { DataArea } from "../../interfaces/dataArea";
import { Labelme } from "../../interfaces/labelme";
import Box from "../Box/box";
import TooltipButton from "../TooltipButton/tooltipButton";
import { FaFileArrowUp, FaFileExport, FaTrashCan } from "react-icons/fa6";
import TooltipSubmit from "../TooltipSubmit/tooltipSubmit";
import { ReservedKeyword } from "../../interfaces/reservedKeyword";
import TooltipButtonScroll from "../TooltipButtonScroll/tooltipButtonScroll";
import {callMothershipDotenv} from "../../utils/api.ts"
import { postLabelmeImages } from '../../utils/api';

import '../../css/labelme.css';
import { useData } from "@/hooks/useData.tsx";
import EnvConfigModalContainer from "@/components/modals/ModalEditDotenv.tsx";

export interface LoaderProps {
    dataAreas: Array<DataArea> | undefined;
    canvasSize: Size<number> | undefined;
    configuration: ReservedKeyword;
    fileName: string;
    loadedImages: Map<string, string>;
    location: Location | null;

    setFileName: (fileName: string) => void;
    loadImageData: (data: ImageData | undefined) => void;
    loadDataAreas: (data: Array<DataArea> | undefined) => void;
    exportDataArea: (labelme: Labelme, canvasSize: Size<number>) => void;
};

export const Loader = (props: LoaderProps) => {
    const {
        selectedFranchise,
        selectedLocation
    } = useData();
    const defaultImageSize: Size<number> = { width: 1280, height: 720 };

    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [imageData, setImageData] = useState<ImageData | undefined>(undefined);
    const [name, setName] = useState<string | undefined>(undefined);
    const [dataAreas, setDataAreas] = useState<any>();

    const [modalOpen, setModalOpen] = useState(false); // Manage modal state

    
    const loadImageFromUrl = async (url: string) => {
        setError(false);
        try {
            const data = await getImageData(url);
            setImageData({
                name: url,
                size: { width: data.width, height: data.height },
                urlResource: url
            });
        } catch (err) {
            console.error(err);
            setError(true);
            setErrorMessage("Failed to load image. Are you sure the submitted URL is valid?");
        }
    };

    const getImageData = (imageUrl: string): Promise<Size<number>> => {
        return new Promise<Size<number>>((resolve, reject) => {
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => {
                resolve({ width: img.naturalWidth, height: img.naturalHeight });
            };
            img.onerror = (err) => {
                console.error(err);
                reject(new Error(`Failed to load image`));
                setError(true);
                setErrorMessage("Failed to load image. Are you sure the submitted URL is valid?");
            };
        });
    };

    const exportDataAreas = (newDesiredSize?: Size<number>): Boolean => {
        
        if (props.dataAreas && props.canvasSize && imageData) {
            props.exportDataArea(
                {
                    version: "4.6.0",
                    flags: {},
                    shapes: props.dataAreas.map((dataArea) => mapDataAreaToShape(dataArea, imageData, newDesiredSize)),
                    imageWidth: newDesiredSize?.width ?? imageData.size.width,
                    imageHeight: newDesiredSize?.height ?? imageData.size.height
                },
                props.canvasSize
            );
        }
        return false;
    };

    const saveDataAreas = (newDesiredSize?: Size<number>) => {
        console.log('saving da')
        if (props.dataAreas && props.canvasSize && imageData) {
            console.log('dataAreas saved')
            setDataAreas(
                {
                    version: "4.6.0",
                    flags: {},
                    shapes: props.dataAreas.map((dataArea) => mapDataAreaToShape(dataArea, imageData, newDesiredSize)),
                    imageWidth: newDesiredSize?.width ?? imageData.size.width,
                    imageHeight: newDesiredSize?.height ?? imageData.size.height
                }
            );
        }
        console.log(dataAreas)
    };

    // Use useEffect to run code after dataAreas state is updated
    useEffect(() => {
        if (dataAreas) {
            console.log('Data areas updated:', dataAreas);
            // Proceed with the AWS sending here, once dataAreas is updated
            handleSendToAWS();
        }
    }, [dataAreas]); // Effect depends on `dataAreas` update

    

    const loadData = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setError(false);
            const dataFile = e.target.files[0];
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                if (e.target) {
                    const fileContent = e.target.result;
                    if (fileContent) {
                        const toJson: Labelme = JSON.parse(fileContent.toString()!);
                        const dataAreas: Array<DataArea> = mapLabelmeToDatashare(
                            toJson,
                            props.canvasSize ?? defaultImageSize,
                            props.configuration
                        );
                        props.loadDataAreas(dataAreas);
                    }
                }
            };
            fileReader.readAsText(dataFile);
        }
    };



    const loadDataFromUrl = async (url: string | undefined) => {
        try {
          setError(false);

          // Fetch the JSON data from the URL
          const response = await fetchJsonData(url);
          console.log('response', response);

          // Since response is already a JSON object, no need to call response.text()
          const toJson: Labelme = response;

          // Map the parsed data to the desired structure
          const dataAreas: Array<DataArea> = mapLabelmeToDatashare(
            toJson, 
            props.canvasSize ?? defaultImageSize,
            props.configuration
          );

          // Pass the mapped data to the loadDataAreas function
          props.loadDataAreas(dataAreas);
        } catch (error) {
          console.error("Error loading data from URL:", error);
          setError(true);
        }
      };




    const fetchJsonData = async (url: any) => {      
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          return data; // This will return the fetched data
        } catch (error) {
          console.error('Error fetching data:', error);
          return null; // Return null in case of an error
        }
      };




    const imageClicked = useCallback((key: string) => {
        console.log('imageClicked is happening')
        const img = props.loadedImages.get(key);
        console.log(img)
        if (img) {
            const imageData: ImageData = {
                name: key,
                size: { width: 1280, height: 720 },
                urlResource: img
            };
            setImageData(imageData);
            // Trigger the canvas to use this image data
            props.loadImageData(imageData);
        }
    }, [props.loadedImages, props.loadImageData]);

    const buttonNames = ["enter", "enter2", "exit", "pull", "pull1", "lobby_areas", "mobile", "pre"];

    async function findRelevantData(name: string) {
        // Concatenate the name with ".json"
        const keyFragment = name + ".json";

        // Iterate over the keys of the map
        for (let key of props.loadedImages.keys()) {
            // Check if the key contains the keyFragment
            if (key.includes(keyFragment)) {
                // Return the value associated with the key
                loadDataFromUrl(props.loadedImages.get(key))
                let dataToLoad = fetchJsonData(props.loadedImages.get(key))
                loadData(dataToLoad)
            }
        }

        // If no matching key is found, return undefined
    }



    const objectToString = (): string => {
        console.log('Sending to AWS with the following object:');
        console.log(dataAreas);
        return JSON.stringify(dataAreas, null, 2);
    }
    
    const handleSendToAWS = async () => {
        const fileName = props.fileName; 
        console.log('Sending data to AWS with file name:', fileName);
        const payload = objectToString();  // This will give you the payload you're sending.
        const result = await postLabelmeImages(selectedFranchise.id, selectedLocation.id, payload, fileName);
    
        if (result) {
            alert("Data successfully sent to AWS.");
        } else {
            alert("Failed to send data to AWS.");
        }
    };
    


    return (
        
        <div className="loader-container">
            <EnvConfigModalContainer
                isOpen={modalOpen}
                setIsOpen={setModalOpen} // Pass setIsOpen to close the modal
                locationId={selectedLocation?.id ?? 1}
                franchiseId={selectedFranchise?.id ?? 1}
            />
            <div className="header">
                {/* Submit, Load, Export, and Delete Buttons */}
                <div className="three-buttons">
                    <TooltipSubmit
                        text="Submit File"
                        fontColor="white"
                        icon={FaFileArrowUp}
                        padding="10px 20px"
                        backgroundColor="#484F56"
                        borderColor="#3d3d3d"
                        hoverColor="#5d5d5d"
                        accept="image/*"
                        onChange={loadImageFromUrl}
                    />
                    <TooltipSubmit
                        text="Load Labels"
                        fontColor="white"
                        padding="10px 20px"
                        icon={FaFileArrowUp}
                        backgroundColor="#1dd1a1"
                        borderColor="#10ac84"
                        hoverColor="#55efc4"
                        disabled={imageData ? false : true}
                        accept=".json"
                        onChange={loadData}
                    />
                    <TooltipButton
                        text="Delete Image"
                        padding="10px 20px"
                        fontColor="white"
                        icon={FaTrashCan}
                        backgroundColor="#ff6b6b"
                        borderColor="#ee5253"
                        hoverColor="#fc5c65"
                        disabled={imageData ? false : true}
                        onClick={() => setImageData(undefined)}
                    />
                </div>

                {/* Export Button */}
                <TooltipButtonScroll
                    text="Export Data"
                    fontColor="white"
                    padding="10px 20px"
                    icon={FaFileExport}
                    backgroundColor="#54a0ff"
                    borderColor="#2e86de"
                    hoverColor="#74b9ff"
                    disabled={props.dataAreas && props.dataAreas.length <= 0 ? true : false}
                    onClick={() => { exportDataAreas({ width: 1280, height: 720 }) }}
                    scrollToId="jsonViewer"
                />
                <TooltipButtonScroll
                    text="Straight To AWS!!"
                    fontColor="red"
                    padding="10px 20px"
                    icon={FaFileExport}
                    backgroundColor="#54a0ff"
                    borderColor="#2e86de"
                    hoverColor="#74b9ff"
                    disabled={props.dataAreas && props.dataAreas.length <= 0 ? true : false}
                    onClick={async () => {
                        // Export the data first
                        await saveDataAreas({ width: 1280, height: 720 });
                        
                        // Then, send the data to AWS
                        // handleSendToAWS();
                    }}
                    scrollToId="jsonViewer"
                />
                <TooltipButton
                    text="Set Dotenv"
                    padding="10px 10px"
                    fontColor="white"
                    backgroundColor="#2e86de"
                    borderColor="#1d6fa5"
                    hoverColor="#4a90d9"
                    onClick={() => { callMothershipDotenv(selectedLocation?.id ?? '1', props.fileName, 'ADD', name) }}
                    disabled={false}
                />
                
                {/* Buttons for different file names */}
                <div className="file-buttons">
                    {buttonNames.map((name) => (
                        <TooltipButton
                            key={name}
                            text={name}
                            padding="10px 10px"
                            fontColor="white"
                            backgroundColor="#2e86de"
                            borderColor="#1d6fa5"
                            hoverColor="#4a90d9"
                            onClick={() => { props.setFileName(name); findRelevantData(name); }}
                            disabled={false}
                        />
                    ))}
                </div>
            </div>

            {/* Display error or loaded image */}
            {/* <div className="status-message">
                {error ? <Box text={errorMessage} backgroundColor="rgba(231,76,60,0.5)" borderColor="rgba(192,57,43,0.8)" /> :
                    imageData ? (
                        <Box text={`Loaded image: ${imageData.name}`} backgroundColor="#2e3440" />
                    ) : <></>}
            </div> */}

            {/* Scrollable images section */}
            <div className="image-gallery">
                {Array.from(props.loadedImages.entries())
                    .filter(([key]) => key.endsWith('.png')) 
                    .map(([key, url]) => (
                        
                        <div className="image-item" key={key} onClick={() => {imageClicked(key); setName(key.split('/')[1].split('.png')[0]); }}>
                            {/* Display the image name before the image */}
                            <div className="image-name">{key}</div>
                            <img src={url} alt={key} />
                        </div>
                    ))}
            </div>
            {/* Button to open the modal */}
            <TooltipButton
                text="Change Dotenv"
                padding="10px 10px"
                fontColor="white"
                backgroundColor="#2e86de"
                borderColor="#1d6fa5"
                hoverColor="#4a90d9"
                onClick={() => setModalOpen(true)} // Open the modal
                disabled={false}
            />
        </div>
    );
};

export default Loader;