/**
 * Presentational component for deleting a group.
 */

import SectionTitle from './SectionTitle';
import StandardButton from '../../../buttons/StandardButton';
import { Franchise } from '@/types/Franchise';
import DropdownFilter from '@/components/dropdowns/DropdownFilter';
import { useState } from 'react';
import { GroupResponse } from '@/types/GroupResponse';
import { useData } from '@/hooks/useData';
import { Roles } from '@/types/Roles';

export default function DeleteGroupSection({
    franchise,
    groups,
    handleDelete,
  }: {
    franchise: Franchise;
    groups: GroupResponse[];
    handleDelete: (id: string) => void;
  }): JSX.Element {
    const [name, setName] = useState<string>("");
    const { profile } = useData();

    // Only admin users can delete public groups
    const groupOptions = profile?.franchiseRoles.get(franchise.id) === Roles.ADMIN 
      ? groups 
      : groups.filter((group) => group.private);

    const options = groupOptions.map((group) => ({
      id: group.groupId,
      value: group.displayName,
    }));

    const selected = options.filter((group) => group.id === name);

    return (
      <section>
        <SectionTitle title="Delete Location Group" />
        <p className="text-slate-600 dark:text-slate-400 mb-4 text-sm">
          Delete a {franchise.displayName} group from this list.
        </p>
        <div className="grid grid-cols-2 gap-4">
          <DropdownFilter
            title={`${selected.length} Selected`}
            options={options}
            selected={selected}
            handleChange={setName}
          >
          </DropdownFilter>
          <StandardButton
            dataCy="delete-group-button"
            onClick={() => handleDelete(name)}
          >
            Delete
          </StandardButton>
        </div>
      </section>
    );
  }