/**
 * Analytics Page
 * This page is intended to empower the customer to uncover
 * insights and trends in their data, over longer periods of time.
 */
import { useState, useEffect, Fragment } from 'react';
import { useData } from '../../hooks/useData';
import type { DayPartData } from '../../types/DayPartData';
import type { RegionsStats } from '../../types/RegionsStats';
import type { Average } from '../../types/Average';
import { getDayPartsData, getRegionsStats, getAverages } from '../../utils/api';
import DropdownFranchise from '../../components/dropdowns/DropdownFranchise';
import DropdownLocationMultiple from '../../components/dropdowns/DropdownLocationMultiple';
import DatepickerWithDefaults from '../../components/time/DatepickerWIthDefaults';
import DriveThruLobbyToggle from '../../components/buttons/DriveThruLobbyToggle';
import HelpButton from '../../components/buttons/HelpButton';
import HelpModal from '../../components/modals/ModalHelpAnalytics';
import TrendsScatter from '../../components/graphs/analytics/TrendsScatter';
import StationsGraph from '../../components/graphs/analytics/StationsGraph';
import TrendsGraph from '../../components/graphs/analytics/TrendsGraph';
import DayPartsGraph from '../../components/graphs/analytics/DayPartsGraph';
import PageLayout from '../../components/ui/PageLayout';
import GroupsButton from '@/components/buttons/GroupsButton';
import ModalBasic from '@/components/modals/ModalBasic';
import GroupModal from '@/components/modals/GroupModal';
import { getAllGroups, getGroupLocationsByName } from '@/utils/api';
import { type GroupResponse } from '@/types/GroupResponse';

export default function AnalyticsContainer({
  version = null,
}: {
  version?: string | null;
}): JSX.Element {
  const {
    selectedFranchise,
    selectedLocations,
    selectedDates,
    setSelectedDates,
    toggled,
    handleSelectGroup,
    cachedGroup
  } = useData();

  const today = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(today.getDate() - 7);

  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const [dayPartsData, setDayPartsData] = useState<DayPartData[]>([]);
  const [regionsData, setRegionsData] = useState<RegionsStats[]>([]);
  const [trendsData, setTrendsData] = useState<Average[]>([]);
  const [groupOptions, setGroupOptions] = useState<GroupResponse[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<GroupResponse | null>(cachedGroup);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Set user locations on load.
  useEffect(() => {
    setSelectedDates([oneWeekAgo, today]);
  }, []);

  useEffect(() => {
    const franchiseId = selectedFranchise?.id || '';
    if (!selectedGroup || !franchiseId) {
      return;
    }
    // Fetch group locations
    getGroupLocationsByName(selectedGroup, franchiseId)
    .then((groupLocations) => { handleSelectGroup(selectedGroup, groupLocations, 'analytics'); });
  }, [selectedGroup]);

  useEffect(() => {
    const franchiseId = selectedFranchise?.id || '';
    if (!franchiseId || !selectedFranchise) {
      return;
    }
    getAllGroups(franchiseId)
    .then((res) => { setGroupOptions(res); });
  }, [selectedFranchise]);

  // Get day parts data when toggled or date or location or franchise changes.
  useEffect(() => {
    if (abortController) {
      abortController.abort();
    }

    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    async function getData(): Promise<void> {
      if (selectedFranchise === null || selectedLocations.length === 0) {
        return;
      }

      // Get day parts data.
      getDayPartsData(
        selectedFranchise.id,
        selectedDates[0],
        selectedDates[1],
        selectedLocations.map((location) => location.id),
        toggled === 'drive-thru',
        { signal: newAbortController.signal } // pass the abort signal
      )
        .then((data) => {
          setDayPartsData(data);
        })
        .catch((error) => {
          console.error(error);
        });

      // Get regions data.
      getRegionsStats(
        selectedFranchise.id,
        selectedDates[0],
        selectedDates[1],
        selectedLocations.map((location) => location.id),
        toggled === 'drive-thru',
        { signal: newAbortController.signal } // pass the abort signal
      )
        .then((data) => {
          setRegionsData(data);
        })
        .catch((error) => {
          console.error(error);
        });

      // Get trends data.
      getAverages(
        selectedFranchise.id,
        selectedDates[0],
        selectedDates[1],
        selectedLocations.map((location) => location.id),
        toggled === 'drive-thru',
        { signal: newAbortController.signal } // pass the abort signal
      )
        .then((data) => {
          setTrendsData(data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    getData().catch((error) => {
      console.error(error);
    });
  }, [toggled, selectedDates, selectedLocations, selectedFranchise]);

  // Handle date change
  function handleDateChange(dates: Date[]): void {
    setSelectedDates(dates);
  }

  const handleRefreshGroups = () => {
    if (selectedFranchise) {
      getAllGroups(selectedFranchise.id)
      .then((res) => { setGroupOptions(res); });
    }
  }

  return (
    <PageLayout>
      <div data-cy="analytics">
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
              Analytics ✨
            </h1>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          {/* Selectors */}
          <div className="sm:flex sm:justify-between sm:items-center mb-8 gap-4">
            <DropdownFranchise />
            <DropdownLocationMultiple />
            {/* Group Filters -- hide if no group options */}
            {groupOptions.length === 0
            ? <Fragment></Fragment>
            : (
              <Fragment>
                <GroupsButton onClick={() => { setIsOpen(prev => !prev); }} />
                <div hidden={true}>
                  <ModalBasic
                    title='Groups'
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    dataCy='modal-filter-groups'
                  >
                    <GroupModal
                      groupOptions={groupOptions}
                      selectedGroup={selectedGroup}
                      setSelectedGroup={setSelectedGroup}
                      refreshGroups={handleRefreshGroups}
                    />
                  </ModalBasic>
                </div>
              </Fragment>)}
            <DatepickerWithDefaults
              initDates={selectedDates}
              handleDateChange={handleDateChange}
              mode='range'
            />
            <DriveThruLobbyToggle />
            <HelpButton
              onClick={() => {
                setShowHelpModal(true);
              }}
            />
          </div>
          {/* Graphs */}
          {version === 'v2'
            ? (
              <TrendsScatter
                trendsData={trendsData}
                dayPartsData={dayPartsData}
                regionsData={regionsData}
              />
            )
            : (
              <Fragment>
                <TrendsGraph trendsData={trendsData} />
                <StationsGraph regionsData={regionsData} />
                <DayPartsGraph dayPartsData={dayPartsData} />
              </Fragment>
            )
          }
        </div>
        {/* Help modal */}
        <HelpModal isOpen={showHelpModal} setIsOpen={setShowHelpModal} />
      </div>
    </PageLayout>
  );
}
