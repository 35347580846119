import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { callMothership } from '../../utils/api';

interface ActionsButtonProps {
  locationId: string;
  onMenuClose: () => void;
}

export default function ActionsButton({
  locationId,
  onMenuClose,
}: ActionsButtonProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl); // Menu open state

  // Menu handling for dropdown
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onMenuClose(); // Ensure the parent component handles menu close logic as well
  };

  const handleAction = (action: string) => {
    callMothership(locationId, action);
    handleClose(); // Close menu after selecting action
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClick}>
        Actions
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => handleAction('refresh_monitors')}>Refresh</MenuItem>
        <MenuItem onClick={() => handleAction('docker_down_up')}>Docker Comp</MenuItem>
        <MenuItem onClick={() => handleAction('flip_monitors')}>Flip Monitors</MenuItem>
        <MenuItem onClick={() => handleAction('remove_update_manager')}>Remove Update Manager</MenuItem>
        <MenuItem onClick={() => handleAction('set_monitor_and_streams')}>Set Monitor and Streams</MenuItem>
        <MenuItem onClick={() => handleAction('run_to_be_cronned')}>Run TBC</MenuItem>
        <MenuItem onClick={() => handleAction('run_update_d_c_file_NEW')}>Run update DC new DO NOT DO THIS!</MenuItem>
        <MenuItem onClick={() => handleAction('retake_images')}>Retake Images</MenuItem>
        <MenuItem onClick={() => handleAction('pull_health')}>Pull Health</MenuItem>
        <MenuItem onClick={() => handleAction('docker_pull')}>Docker Pull</MenuItem>
        <MenuItem onClick={() => handleAction('set_HM_background')}>HM background</MenuItem>
        <MenuItem onClick={() => handleAction('set_second_screen_camera')}>set second screen camera</MenuItem>
        <MenuItem onClick={() => handleAction('set_second_screen_monitor')}>set second screen monitor</MenuItem>
        <MenuItem onClick={() => handleAction('setup_xauthority')}>set xauthority</MenuItem>
        <MenuItem onClick={() => handleAction('disk_cleanup')}>disk cleanup DO NOT PRESS</MenuItem>
        <MenuItem onClick={() => handleAction('reboot')}>reboot DO NOT PRESS</MenuItem>
      </Menu>
    </div>
  );
}
