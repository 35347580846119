import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import ActionsButton from '../buttons/ActionsButton'; // Import the new ActionsButton component


import {
  Button,
  Menu,
  MenuItem,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import {
  getHistoricalStream,
  getLiveStream,
  getStreamTimes,
} from '../../utils/video';
import type { Location } from '../../types/Location';
import type { Franchise } from '../../types/Franchise';
import { useCamera } from '../../hooks/useCamera';
import VideoPlayer from './VideoPlayer';
import { callMothership, getMothershipMetrics, getMothershipLogs } from '../../utils/api';

export default function VideoPlayerKinesis({
  location,
  camera,
  metrics = {}, // Set an empty object as a default if metrics is undefined or null
  franchise,
  showMetrics, // Receive showMetrics prop
}: {
  location: Location;
  camera: string;
  metrics?: object | null; // Allow null as a valid value for metrics
  franchise?: Franchise | undefined;
  showMetrics: Record<string, boolean>; // Add showMetrics as a prop
}): JSX.Element {
  const {
    kinesisVideo,
    isLive,
    playbackSpeed,
    videoDateTime,
    setVideoDateTime,
    isPlaying,
    setIsPlaying,
  } = useCamera();
  const videoRef = useRef<HTMLVideoElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [player, setPlayer] = useState<videojs.Player | null>(null);

  const [showAllCamerasOut, setShowAllCamerasOut] = useState(false);
  const [showAllNmapResults, setShowAllNmapResults] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // For MUI Menu
  const open = Boolean(anchorEl); // Check if the menu is open
  const [logs, setLogs] = useState<string>(''); // State to hold logs
  const [logsOpen, setLogsOpen] = useState(false); // State for popup visibility
  let restartCvPastWeek = ''
  let restartAllPastMonth = ''
  // Ensure systemStatus has a fallback in case metrics is null
  const systemStatus = metrics || {}; // If metrics is null or undefined, use an empty object as fallback

  // Menu handling for dropdown
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Hook to get the current location
  const locationPath = useLocation();

  // Initialize videojs on component mount
  useEffect(() => {
    if (videoRef.current === null) {
      return;
    }
    const playerObject = videojs(videoRef.current, {
      autoplay: true,
      muted: true,
      playsinline: true,
      controls: true,
      preload: 'auto',
      userActions: {
        click: false,
      },
      html5: {
        vhs: {
          overrideNative: true,
        },
      },
      disablePictureInPicture: true,
      nativeControlsForTouch: false,
    });
    setPlayer(playerObject);

    playerObject.on('error', () => {
      playerObject.load();
      playerObject.play()?.catch((err) => {
        console.log(err);
      });
    });

    playerObject.on('loadedmetadata', () => {
      const w = wrapperRef.current?.clientWidth;
      playerObject.width(w);
    });

    if (isLive) {
      goToLive().catch((err) => {
        console.log(err);
      });
    } else {
      goToHistorical().catch((err) => {
        console.log(err);
      });
    }

    setIsPlaying(true);

    return () => {
      if (player !== null) {
        player.dispose();
      }
    };
  }, [videoRef.current]);

  // Play or pause the video based on isPlaying state
  useEffect(() => {
    if (player === null) {
      return;
    }

    if (!isPlaying) {
      player.pause();
    } else {
      player.play()?.catch((err: any) => {
        console.log(err);
      });
    }
  }, [isPlaying]);

  // Change video speed
  useEffect(() => {
    if (player === null) {
      return;
    }
    try {
      player.playbackRate(playbackSpeed);
      player.defaultPlaybackRate(playbackSpeed);
    } catch (err) {
      console.log(err);
    }
  }, [playbackSpeed]);

  // Switch between live and historical video
  useEffect(() => {
    if (player === null) {
      return;
    }

    if (isLive) {
      goToLive().catch((err) => {
        console.log(err);
      });
    } else {
      goToHistorical().catch((err) => {
        console.log(err);
      });
    }
  }, [player, isLive, videoDateTime, location, camera]);

  // Get stream name based on the camera type
  function getStreamName(): string {
    let streamName = '';
    if (camera === 'CV') {
      streamName = `CV_${location.id}`;
    } else if (camera === 'CV_DT') {
      streamName = `CV_DT_${location.id}`;
    } else if (camera === 'MONITOR') {
      streamName = `MONITOR_${location.id}`;
    } else {
      streamName = `${location.id}_${camera}`;
    }
    return streamName;
  }

  // Jump to the live stream
  async function goToLive(): Promise<void> {
    if (player === null || kinesisVideo === undefined) {
      return;
    }

    try {
      const streamUrl = await getLiveStream(getStreamName(), kinesisVideo);

      player.src({
        src: streamUrl,
        type: 'application/x-mpegURL',
      });
      player.playbackRate(1);
      player.defaultPlaybackRate(1);
    } catch (err) {
      console.log('Error going to live stream.');
      console.warn(err);
    }
  }

  // Jump to the historical stream
  async function goToHistorical(): Promise<void> {
    if (player === null || kinesisVideo === undefined) {
      return;
    }

    try {
      player.on('ended', () => {
        console.log(videoDateTime);
        setVideoDateTime(new Date(videoDateTime.valueOf() + 10 * 60 * 1000));
        console.log(videoDateTime);
      });
      const { startTime, endTime } = getStreamTimes(
        videoDateTime,
        location.timezone
      );

      const streamUrl = await getHistoricalStream(
        getStreamName(),
        kinesisVideo,
        startTime,
        endTime
      );

      player.src({
        src: streamUrl,
        type: 'application/x-mpegURL',
      });
    } catch (err) {
      console.log('Error going to historical stream.');
      console.warn(err);
    }
  }

  // Render a circle based on boolean state
  const renderCircle = (value: boolean) => {
    return (
      <span
        style={{
          width: 12,
          height: 12,
          borderRadius: '50%',
          backgroundColor: value ? 'green' : 'red',
          display: 'inline-block',
          marginLeft: 5,
        }}
      ></span>
    );
  };

  // Render circle with color based on the value of ram_usage
  const renderRamUsageCircle = (ramUsage: number) => {
    let color = 'green'; // Default to green
    if (ramUsage > 70 && ramUsage < 90) {
      color = 'yellow'; // Yellow for RAM usage > 70 and < 90
    } else if (ramUsage >= 90) {
      color = 'red'; // Red for RAM usage >= 90
    }
    return <span style={{ width: 12, height: 12, borderRadius: '50%', backgroundColor: color, display: 'inline-block', marginLeft: 5 }}></span>;
  };

  // Render circle with color based on the value of disk_usage
  const renderDiskUsageCircle = (diskUsage: number) => {
    let color = 'green'; // Default to green
    if (diskUsage > 45 && diskUsage < 70) {
      color = 'orange'; // Orange for disk usage > 45 and < 70
    } else if (diskUsage >= 70) {
      color = 'red'; // Red for disk usage >= 70
    }
    return <span style={{ width: 12, height: 12, borderRadius: '50%', backgroundColor: color, display: 'inline-block', marginLeft: 5 }}></span>;
  };

  // Render circle with color based on the value of upload speed
  const renderUploadSpeedCircle = (uploadSpeed: number) => {
    let color = 'red'; // Default to red for upload speed < 10
    if (uploadSpeed >= 10 && uploadSpeed <= 20) {
      color = 'yellow'; // Yellow for upload speed between 10 and 20
    } else if (uploadSpeed > 20) {
      color = 'green'; // Green for upload speed > 20
    }
    return <span style={{ width: 12, height: 12, borderRadius: '50%', backgroundColor: color, display: 'inline-block', marginLeft: 5 }}></span>;
  };

  // Render circles based on cv restarts
  const renderRestartCVCircle = (restart_cv: string) => {
    try {
      const parseDates = (dateString: string): Date[] => {
        return dateString.split(',').map(date => new Date(date.trim()));
      };

      const isWithinPastWeek = (date: Date): boolean => {
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        return date >= oneWeekAgo;
      };

      const dates = parseDates(restart_cv);
      const pastWeekDates = dates.filter(isWithinPastWeek);
      const count = pastWeekDates.length;
      let restartCvPastWeek = pastWeekDates
        .map(date => date.toLocaleString('en-US', { 
          year: 'numeric', 
          month: 'long', 
          day: 'numeric', 
          hour: 'numeric', 
          minute: 'numeric', 
          second: 'numeric', 
          hour12: true // Optionally, use 12-hour format (AM/PM)
        }))
        .join(", ");

      const circles = [];

      if (count === 1) {
        circles.push(
          <span
            key="yellow"
            style={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: 'yellow',
              display: 'inline-block',
              marginLeft: 5,
            }}
          ></span>
        );
      } else if (count > 1) {
        for (let i = 0; i < count - 1; i++) {
          circles.push(
            <span
              key={`red-${i}`}
              style={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: 'red',
                display: 'inline-block',
                marginLeft: 5,
              }}
            ></span>
          );
        }
      }

      if (count === 0) {
        circles.push(
          <span
            key="green"
            style={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: 'green',
              display: 'inline-block',
              marginLeft: 5,
            }}
          ></span>
        );
      }

      return <>{circles}</>;
    } catch (error) {
      console.error('Error rendering Restart CV Circle:', error);
      return <></>; // No circles in case of error
    }
  };

  const renderRestartAllCircle = (restart_all: string) => {
    try {
      const parseDates = (dateString: string): Date[] => {
        return dateString.split(',').map(date => new Date(date.trim()));
      };

      const isWithinPastMonth = (date: Date): boolean => {
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        return date >= oneMonthAgo;
      };

      const isWithinPastTwoWeeks = (date: Date): boolean => {
        const twoWeeksAgo = new Date();
        twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
        return date >= twoWeeksAgo;
      };

      const isWithinPastWeek = (date: Date): boolean => {
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        return date >= oneWeekAgo;
      };

      const dates = parseDates(restart_all);
      const pastMonthDates = dates.filter(isWithinPastMonth);
      const pastTwoWeeksDates = dates.filter(isWithinPastTwoWeeks);
      const pastWeekDates = dates.filter(isWithinPastWeek);
      let restartAllPastMonth = pastWeekDates
        .map(date => date.toLocaleString('en-US', { 
          year: 'numeric', 
          month: 'long', 
          day: 'numeric', 
          hour: 'numeric', 
          minute: 'numeric', 
          second: 'numeric', 
          hour12: true // Optionally, use 12-hour format (AM/PM)
        }))
        .join(", ");

      const circles = [];

      if (pastWeekDates.length > 0) {
        for (let i = 0; i < pastWeekDates.length; i++) {
          circles.push(
            <span
              key={`red-${i}`}
              style={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: 'red',
                display: 'inline-block',
                marginLeft: 5,
              }}
            ></span>
          );
        }
      } else if (pastTwoWeeksDates.length > 0) {
        circles.push(
          <span
            key="red"
            style={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: 'red',
              display: 'inline-block',
              marginLeft: 5,
            }}
          ></span>
        );
      } else if (pastMonthDates.length > 0) {
        circles.push(
          <span
            key="yellow"
            style={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: 'yellow',
              display: 'inline-block',
              marginLeft: 5,
            }}
          ></span>
        );
      } else {
        circles.push(
          <span
            key="green"
            style={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: 'green',
              display: 'inline-block',
              marginLeft: 5,
            }}
          ></span>
        );
      }

      return <>{circles}</>;
    } catch (error) {
      console.error('Error rendering Restart All Circle:', error);
      return <></>; // No circles in case of error
    }
  };

  const getCollectionTimestampColor = (collection_timestamp: string): string => {
    try {
      const timestamp = new Date(collection_timestamp);
      const now = new Date();
      const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      const differenceInMilliseconds = utcNow.getTime() - timestamp.getTime();
      console.log('aaa',utcNow, timestamp)
      const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

      if (differenceInHours > 2) {
        return 'red'; // Red for over 2 hours
      } else if (differenceInHours > 1) {
        return 'yellow'; // Yellow for over 1 hour but less than 2
      }
      return 'green'; // Default to green
    } catch (error) {
      console.error('Error calculating Collection Timestamp color:', error);
      return 'green'; // Default to green on error
    }
  };

  // Render the actual circle separately
  const renderCollectionTimestampCircle = (collection_timestamp: string): JSX.Element => {
    const color = getCollectionTimestampColor(collection_timestamp);
    return (
      <span
        style={{
          width: 12,
          height: 12,
          borderRadius: '50%',
          backgroundColor: color,
          display: 'inline-block',
          marginLeft: 5,
        }}
      ></span>
    );
  };




  

  const handleLogsOpen = async () => {
    try {
      console.log('Fetching logs for:', {
        franchiseId: franchise.id,
        locationId: location.id,
      });

      const fetchedLogs = await getMothershipLogs(franchise.id, [location.id]); // Fetch logs

      if (fetchedLogs) {
        console.log('Fetched Logs:', fetchedLogs); // Log fetched logs
        setLogs(fetchedLogs); // Set logs in state
      } else {
        console.warn('No logs were returned by getMothershipLogs.');
        setLogs('No logs found.'); // Handle case where logs are empty or null
      }
    } catch (error) {
      console.error('Error fetching logs:', error);
      setLogs('Failed to load logs.'); // Set error message if fetching fails
    } finally {
      console.log('Opening logs dialog...');
      setLogsOpen(true); // Open the dialog
    }
  };

  // Close the logs dialog
  const handleLogsClose = () => {
    setLogsOpen(false); // Close the dialog
  };




  const compareCameras = (
    camerasOutContents: string,
    nmapResults: string,
    showAllCamerasOut: boolean,
    setShowAllCamerasOut: React.Dispatch<SetStateAction<boolean>>,
    showAllNmapResults: boolean,
    setShowAllNmapResults: React.Dispatch<SetStateAction<boolean>>
  ): JSX.Element => {
    try {
      const camerasOutList = camerasOutContents.split('\n').map(ip => ip.trim());
      const nmapResultList = nmapResults.split(',').map(ip => ip.trim());
  
      const onlyInCamerasOut = camerasOutList.filter(ip => !nmapResultList.includes(ip));
      const onlyInNmapResults = nmapResultList.filter(ip => !camerasOutList.includes(ip));
  
      const hasDiscrepancy = onlyInCamerasOut.length > 0 || onlyInNmapResults.length > 0;
  
      return (
        <div style={{ marginTop: '10px' }}>
          {/* Circle to indicate discrepancy */}
          <span
            style={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: hasDiscrepancy ? 'red' : 'green',
              display: 'inline-block',
              marginRight: 5,
            }}
          ></span>
          <span>{hasDiscrepancy ? 'Discrepancy found!' : 'No discrepancies'}</span>
  
          {/* Cameras Out Contents Section */}
          <div style={{ marginTop: '10px' }}>
            <strong
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => { setShowAllCamerasOut(!showAllCamerasOut); }}
            >
              Cameras Out Contents ({camerasOutList.length})
            </strong>
            <pre>
              {(showAllCamerasOut ? camerasOutList : onlyInCamerasOut).map(ip => (
                <span
                  key={`camera-${ip}`}
                  style={{
                    color: onlyInCamerasOut.includes(ip) ? 'red' : 'white',
                  }}
                >
                  {ip}
                  {'\n'}
                </span>
              ))}
            </pre>
          </div>
  
          {/* Nmap Results Section */}
          <div style={{ marginTop: '10px' }}>
            <strong
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => { setShowAllNmapResults(!showAllNmapResults); }}
            >
              Nmap Results ({nmapResultList.length})
            </strong>
            <pre>
              {(showAllNmapResults ? nmapResultList : onlyInNmapResults).map(ip => (
                <span
                  key={`nmap-${ip}`}
                  style={{
                    color: onlyInNmapResults.includes(ip) ? 'red' : 'white',
                  }}
                >
                  {ip}
                  {'\n'}
                </span>
              ))}
            </pre>
          </div>
        </div>
      );
    } catch (error) {
      console.error('Error comparing cameras:', error);
      return <span>Error comparing cameras.</span>;
    }
  };

  const getMetricVisibility = (metric: string) => {
    if (!showMetrics) {
      return true;
    }
    if (showMetrics === null) {
      return true;  // Show all metrics if showMetrics is null
    }
    return showMetrics[metric] !== false;  // Use the value from showMetrics or default to true
  };


  return (
    <div>
      <VideoPlayer
        location={location}
        cameraId={camera}
        videoRef={videoRef}
        wrapperRef={wrapperRef}
      />

      {camera === 'MONITOR' && locationPath.pathname === '/mothership' && ( // Only show if on /mothership
        <div>
          <ActionsButton
            locationId={location.id}
            onMenuClose={() => {
              // You can perform additional logic when the menu closes, if necessary
            }}
          />

          {/* New button to open logs popup */}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleLogsOpen}
            style={{ marginLeft: '10px' }} // Adjust spacing
          >
            View Logs
          </Button>

          {/* Logs Popup */}
          <Dialog open={logsOpen} onClose={handleLogsClose} fullWidth maxWidth="md">
            <DialogTitle>Mothership Logs</DialogTitle>
            <DialogContent>
              <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                {logs}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleLogsClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Render the system status information */}
          {Object.keys(systemStatus).length > 0 && (
            <div
            style={{
              marginTop: 10,
              backgroundColor: getCollectionTimestampColor(systemStatus.timestamp) === 'red' ? 'rgba(255, 0, 0, 0.2)' : 'transparent',
              padding: '10px',
              borderRadius: '8px',
            }}
          >
              {getMetricVisibility("internetConnection") && (
                <div>
                  {renderCircle(metrics.internet_connection)} <span>Internet Connection: {metrics.internet_connection ? 'Connected' : 'Disconnected'}</span>
                </div>
              )}
              {getMetricVisibility("ramUsage") && (
            <div>
              {renderRamUsageCircle(metrics.ram_usage)} <span>RAM Usage: {metrics.ram_usage}%</span>
            </div>
          )}
          {getMetricVisibility("diskUsage") && (
            <div>
              {renderDiskUsageCircle(metrics.disk_usage)} <span>Disk Usage: {metrics.disk_usage}%</span>
            </div>
          )}
          {getMetricVisibility("uploadSpeed") && (
            <div>
              {renderUploadSpeedCircle(metrics.speedtest_upload)} <span>Upload Speed: {metrics.speedtest_upload} Mbps</span>
            </div>
          )}
          {getMetricVisibility("restartCv") && (
            <div>
              {renderRestartCVCircle(metrics.restart_cv)} <span>CV Restarts: {metrics.restart_cv}</span>
            </div>
          )}
          {getMetricVisibility("restartAll") && (
            <div>
              {renderRestartAllCircle(metrics.restart_all)} <span>ALL Restarts: {metrics.restart_all}</span>
            </div>
          )}
          
              {systemStatus.cameras_out_contents && systemStatus.nmap_result && (
                <div>
                  {compareCameras(
                    systemStatus.cameras_out_contents,
                    systemStatus.nmap_result,
                    showAllCamerasOut,
                    setShowAllCamerasOut,
                    showAllNmapResults,
                    setShowAllNmapResults
                  )}
                </div>
              )}

              {getMetricVisibility("collectionTimestamp") && (
                <div>
                  {renderCollectionTimestampCircle(metrics.timestamp)} <span>Last Seen: {metrics.timestamp}</span>
                </div>
              )}

            </div>
          )}
        </div>
      )}
    </div>
  );
}
