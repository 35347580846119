import { GroupResponse } from '@/types/GroupResponse';
import { useData } from '@/hooks/useData';
import { useState } from 'react';
import { useToast } from '@/hooks/useToast';
import { createGroup, deleteGroup } from '@/utils/api';
import CreateGroupSection from '../settings/account/sections/CreateGroupSection';
import DeleteGroupSection from '../settings/account/sections/DeleteGroupSection';
import SectionTitle from '../settings/admin/SectionTitle';
import React from 'react';
import { Roles } from '@/types/Roles';

export default function GroupModal({
    groupOptions,
    selectedGroup, 
    setSelectedGroup,
    refreshGroups,
}: {
    groupOptions: GroupResponse[];
    selectedGroup: GroupResponse | null;
    setSelectedGroup: React.Dispatch<React.SetStateAction<GroupResponse | null>>;
    refreshGroups: () => void;
}): JSX.Element {
    const [name, setName] = useState('');
    const [group, setGroup] = useState<string[]>([]);
    const [isNewPrivate, setIsNewPrivate] = useState<boolean>(true);
    const { setToast } = useToast();
  
    const { profile, displayedLocations, selectedFranchise } = useData();

  const handleGroupChange = (locationId: string) => {
    setGroup((prev) => {
      if (prev.includes(locationId)) {
        return prev.filter((loc) => loc !== locationId);
      } else {
        return [...prev, locationId];
      }
    });
  };

  // Handle creating new group.
  async function handleNewGroup(name: string, group: string[]): Promise<void> {
    if (!selectedFranchise) return;

    const success = await createGroup(name, selectedFranchise.id, group, isNewPrivate);
    setToast({
      show: true,
      message: success
        ? `New group called ${name} created`
        : `Error in creating group ${name}`,
      type: success ? 'success' : 'error',
    });
    refreshGroups();
  }

  // Handle Deleting a group.
  async function handleDeleteGroup(name: string): Promise<void> {
    if (!selectedFranchise) return;

    const success = await deleteGroup(name, selectedFranchise.id);
    setToast({
      show: true,
      message: success
        ? `Group called ${name} deleted`
        : `Error in deleting group ${name}`,
      type: success ? 'success' : 'error',
    });
    refreshGroups();
  }

  
  const handleSelection = (group: GroupResponse) => {
    group.groupId === selectedGroup?.groupId ? setSelectedGroup(null) : setSelectedGroup(group);
  };

  const handleRemove = () => {
    setSelectedGroup(null);
  };

  const handlePrivateChange = (isPrivate: boolean) => {
    setIsNewPrivate(isPrivate);
  };

  let isAdmin = false;
  if (selectedFranchise) {
    isAdmin = profile?.franchiseRoles.get(selectedFranchise?.id) === Roles.ADMIN
  }

  const liClassName = "cursor-pointer w-4 h-4 text-blue-600 bg-slate-100 border-slate-300 rounded-sm \
    focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-slate-700 dark:focus:ring-offset-slate-700 \
    focus:ring-2 dark:bg-slate-600 dark:border-slate-500";

  return (
    <div className="flex flex-col" style={{ overflowY: 'scroll' }}>
      <div className={'m-1 p-2 border border-slate-100 dark:border-slate-700 rounded-md'}>
        <SectionTitle title="Select a Group" />
        <p className="text-slate-600 dark:text-slate-400 mb-4 text-sm">
          Select a group of locations to see data for.
        </p>
        <ul className={"text-sm font-medium text-slate-900 bg-white border border-slate-200 rounded-lg dark:bg-slate-700 dark:border-slate-600 dark:text-white"}>
        {groupOptions.map((group, i) => (
          <li key={`${group.groupId}-${i}`} className={"w-full"}>
            <div 
            className={"flex items-center ps-3"} 
            >
              {group.groupId === selectedGroup?.groupId 
              ? (
              <input checked id={`list-radio-${group.groupId}-checkbox`} type="radio" value="" name="list-radio" onChange={() => handleSelection(group)} className={liClassName}/>
              ) : (
              <input id={`list-radio-${group.groupId}-checkbox`} type="radio" value="" name="list-radio" onChange={() => handleSelection(group)} className={liClassName}/>
              )}
              <label htmlFor={`list-radio-${group.groupId}-checkbox`} className={"w-full py-2 ms-2 text-sm font-medium text-slate-900 dark:text-slate-300"}>
                {group.displayName ? group.displayName : group.groupId}
              </label>
            </div>
          </li>
        ))}
        </ul>
        <div className="grid grid-cols-3 gap-4">
          <div 
          className={`p-2 my-1 cursor-pointer font-medium shadow-sm
            text-sm text-indigo-500 dark:text-white flex justify-center
            bg-white dark:bg-slate-700
            border border-slate-200 hover:border-slate-300 rounded-lg dark:border-slate-700 dark:hover:border-slate-600`}
          onClick={() => handleRemove()}>
            Remove Selection
          </div>
        </div>
      </div>

      {/* Create Group */}
      {selectedFranchise && (
        <div className={'m-1 p-2 border border-slate-100 dark:border-slate-700 rounded-md'}>
          <CreateGroupSection
            locations={displayedLocations.filter(
              (loc) => loc.franchiseId === selectedFranchise.id
            )}
            franchise={selectedFranchise}
            name={name}
            setName={setName}
            group={group}
            isAdmin={isAdmin}
            handlePrivateChange={handlePrivateChange}
            handleChange={handleGroupChange}
            handleCreate={(name, group) => {
              handleNewGroup(name, group).catch(console.error);
            }}
          />
        </div>
      )}

      {/* Delete Group */}
      {selectedFranchise && (
        <div className={'m-1 p-2 border border-slate-100 dark:border-slate-700 rounded-md'}>
          <DeleteGroupSection
            franchise={selectedFranchise}
            groups={groupOptions}
            handleDelete={(name) => {
              handleDeleteGroup(name).catch(console.error);
            }}
          />
        </div>
      )}
    </div>
  );
}
