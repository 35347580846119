import { useEffect, useState } from 'react';
import {
  Carousel,
  type CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../ui/carousel';

const TableauEmbed = () => {
  const [api, setApi] = useState<CarouselApi>();

  useEffect(() => {
    if (api == null) {
      return;
    }
    const hash = window.location.hash.replace('#', '');
    const slideMap = { curbside: 0, dtrevenue: 1, svcTimeDash: 2 };

    if (slideMap[hash] !== undefined && api != null) {
      api.scrollTo(slideMap[hash]);
    }
  }, [api]);

  return (
    <div>
      <Carousel setApi={setApi}>
        <CarouselContent>
          <CarouselItem id="curbside">
            <tableau-viz
              src="https://us-east-1.online.tableau.com/t/samm-1cfb84cac2/views/Angel-CFSDashboardsViewJan23/AA-Curbside"
              width="100%"
              height="840"
              hide-tabs
              toolbar="bottom"
            />
          </CarouselItem>
          <CarouselItem id="dtrevenue">
            <tableau-viz
              id="tableauViz"
              src="https://us-east-1.online.tableau.com/t/samm-1cfb84cac2/views/Angel-CFSDashboardsViewJan23/AA-DTRevenue"
              width="100%"
              height="840"
              center="true"
              hide-tabs
              toolbar="bottom"
            />
          </CarouselItem>
          <CarouselItem id="svcTimeDash">
            <tableau-viz
              id="tableauViz"
              src="https://us-east-1.online.tableau.com/t/samm-1cfb84cac2/views/Angel-CFSDashboardsViewJan23/AA-SvcTimeDash"
              width="100%"
              height="840"
              hide-tabs
              toolbar="bottom"
            />
          </CarouselItem>
          <CarouselItem id="curbside-volumes-and-times">
            <tableau-viz
              id="tableauViz"
              src="https://us-east-1.online.tableau.com/t/samm-1cfb84cac2/views/Angel-CFSDashboardsViewJan23/AA-CurbsideVolumesandTimes"
              width="100%"
              height="840"
              hide-tabs
              toolbar="bottom"
            />
          </CarouselItem>
          <CarouselItem id="cashier">
            <tableau-viz
              id="tableauViz"
              src="https://us-east-1.online.tableau.com/t/samm-1cfb84cac2/views/Angel-CFSDashboardsViewJan23/AA-CashierIDServiceTime"
              width="100%"
              height="840"
              hide-tabs
              toolbar="bottom"
            />
          </CarouselItem>
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  );
};

export default TableauEmbed;
